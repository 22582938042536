/* tslint:disable */
/* eslint-disable */
/**
 * Pomelo Backend Server
 * This is the backend API
 *
 * The version of the OpenAPI document: 1.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface DeviceValidationResponse {
  /**
   * The status of the device validation
   */
  status: DeviceValidationResponseStatusEnum
}

export const DeviceValidationResponseStatusEnum = {
  Success: "SUCCESS",
  Expired: "EXPIRED",
  AlreadyConfirmed: "ALREADY_CONFIRMED",
} as const
export type DeviceValidationResponseStatusEnum =
  (typeof DeviceValidationResponseStatusEnum)[keyof typeof DeviceValidationResponseStatusEnum]
