import { useState } from "react"
import * as Yup from "yup"
import { TextField, VSpace } from "@pomebile/design-system"
import { Checkbox, HStack, Link, Secure, Txt, VStack } from "@pomebile/primitives"
import { sprinkles } from "@pomebile/primitives-web"
import * as V from "../utils/formValidation/validationMessages"
import { StickyBottom } from "../components/StickyBottom"
import { FormSubmitButton } from "../components/Form/FormSubmitButton"
import { ScreenForm } from "../components/ScreenForm"
import { useForm } from "../components/Form/useForm"
import { TermsAndConditionsDialog } from "../components/TermsAndConditionsDialog"

const IncomeSchema = Yup.object({
  statedIncome: Yup.string().required(V.MSG_REQUIRED).max(11, V.MSG_INVALID_INCOME),
  // 11 chars = 1 billion (since input is string, not number), max income is 999,999,999
  acceptedTOS: Yup.boolean().oneOf([true], V.MSG_REQUIRED),
})

export type CollectedIncomeDetails = {
  statedIncome: number
}

export type CollectedIncomeProps = {
  onDone: (income: number) => void
}

export interface IncomeInfo extends Yup.InferType<typeof IncomeSchema> {}

const initialFormValues: IncomeInfo = {
  statedIncome: "",
  acceptedTOS: false,
}

export function Income({ onDone }: CollectedIncomeProps) {
  const [termsDialog, setTermsDialog] = useState(false)
  const [submit, getFieldProps, status, { isFormValid }] = useForm({
    name: "Income", // Note: Must match 1.0 name for analytics
    schema: IncomeSchema,
    initial: initialFormValues,
    submit: async (details) => {
      const income = Number(details.statedIncome.replace(/\D/g, ""))
      onDone(income)
    },
  })

  const { onChange, ...restOfIncomeProps } = getFieldProps("statedIncome")

  const handleIncomeChange = (inputValue: string) => {
    // Remove non-digit characters and format with commas
    const formattedIncome: string = inputValue
      .replace(/\D/g, "")
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
    onChange(formattedIncome)
  }

  const checkboxFieldProps = getFieldProps("acceptedTOS")

  return (
    <ScreenForm onSubmit={submit}>
      <VStack gap="xs">
        <Txt variant="headline2" as="h2">
          Submit your application
        </Txt>
        <VSpace height="xs" />
        <Txt>
          To see which plan you are approved for, please enter the total amount of income you have
          access to per year, before taxes.
        </Txt>
        <VSpace height="xl2" />

        <Secure>
          <TextField
            label="Total annual income"
            type="text"
            {...restOfIncomeProps}
            supportingText="This can include income earned by others, like a spouse, if the income is deposited into your individual or joint account."
            inputAdornment={<Txt>$&nbsp;</Txt>}
            placeholder="0"
            onChange={handleIncomeChange}
          />
        </Secure>

        <HStack gap="md" alignItems="flex-start" justifyContent="center">
          {/* Wrapper here helps a little with style alignment */}
          <div data-public={true}>
            <Checkbox
              id="legal"
              checked={checkboxFieldProps.value}
              onChange={checkboxFieldProps.onChange}
            />
          </div>
          <label className={sprinkles({ lineHeight: "none" })}>
            <Txt variant="caption">
              I agree to be evaluated for Pomelo’s credit products and acknowledge and agree to the{" "}
              <Link color="primary" decoration="underline" onClick={() => setTermsDialog(true)}>
                Cardholder Agreement
              </Link>
              ,{" "}
              <Link color="primary" decoration="underline" onClick={() => setTermsDialog(true)}>
                Terms of Service
              </Link>{" "}
              &{" "}
              <Link color="primary" decoration="underline" onClick={() => setTermsDialog(true)}>
                Privacy Policy
              </Link>
              .
            </Txt>
          </label>
        </HStack>
      </VStack>

      <StickyBottom>
        {!!checkboxFieldProps.errorText && (
          <VStack justifyContent="center">
            <Txt textAlign="center" variant="caption" color="text-error">
              You must agree to the terms & conditions.
            </Txt>
            <VSpace height="md" />
          </VStack>
        )}
        <FormSubmitButton disabled={!isFormValid} status={status}>
          Submit
        </FormSubmitButton>
        <VSpace height="md" />
        <VStack justifyContent="center">
          <Txt textAlign="center" variant="caption">
            Viewing your offer won't impact your credit score.
          </Txt>
        </VStack>
      </StickyBottom>

      <TermsAndConditionsDialog
        open={termsDialog}
        showGCashPromo={false} // We haven't shown the GCash promo yet at this point
        onClose={() => setTermsDialog(false)}
      />
    </ScreenForm>
  )
}
