/* tslint:disable */
/* eslint-disable */
/**
 * Pomelo Backend Server
 * This is the backend API
 *
 * The version of the OpenAPI document: 1.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface EmailConfirmationResponse {
  /**
   * The status of the email validation process
   */
  status?: EmailConfirmationResponseStatusEnum
}

export const EmailConfirmationResponseStatusEnum = {
  CodeMismatch: "CODE_MISMATCH",
  TokenExpired: "TOKEN_EXPIRED",
  Success: "SUCCESS",
  AlreadyValidated: "ALREADY_VALIDATED",
} as const
export type EmailConfirmationResponseStatusEnum =
  (typeof EmailConfirmationResponseStatusEnum)[keyof typeof EmailConfirmationResponseStatusEnum]
