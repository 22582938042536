import {
  AlertIcon,
  CameraIcon,
  DeviceMobileCameraIcon,
  IdCardIcon,
  Callout,
} from "@pomebile/design-system"
import { Avatar, Button, ButtonState, HStack, Txt, VStack } from "@pomebile/primitives"
import { StickyBottom } from "../components/StickyBottom"
import {
  ActiveVeriffSessionResponse,
  GenerateVeriffResponse,
  VeriffSdkResponse,
  useVeriff,
} from "../utils/veriff"
import { match } from "@pomebile/shared/tagged-union"
import { SubmittingDialog } from "./Veriff/SubmittingDialog"
import { ResubmissionDialog } from "./Veriff/ResubmissionDialog"
import { VeriffEv, VeriffOutcome } from "./Veriff/veriffStateMachine"
import { ScreenFrame } from "../components/ScreenFrame"

export type VeriffProps = {
  api: {
    queryDecision: () => Promise<VeriffOutcome>
    getActiveVeriffSession: () => Promise<ActiveVeriffSessionResponse>
    generateVeriffSession: () => Promise<GenerateVeriffResponse>
    startVeriffSdk: (url: string) => Promise<VeriffSdkResponse>
  }
  onDone: (result: VeriffOutcome) => void
}

export function Veriff({ api, onDone }: VeriffProps) {
  const [state, send] = useVeriff({
    reportOutcome: async (outcome: VeriffOutcome) => {
      onDone(outcome)
    },
    ...api,
  })

  const handleContinue = () => {
    // if Veriff opens but has an error (not reached if Veriff can't be opened at all)
    if (state.tag === "VeriffSdkError" && state.data.error === "sdk_needs_reload") {
      window.location.reload()
      return
    }

    send(VeriffEv.UserPressedContinue())
  }

  const sdkError: string | undefined = match(
    state,
    {
      VeriffSdkError: (data): string | undefined => {
        switch (data.error) {
          case "user_cancelled":
            return "Identity verification is required to continue, please resume verification."
          case "sdk_needs_reload":
            return "An error occurred during verification. Please ensure you allow camera permissions when prompted. You must reload the page to try again."
          case "cannot_open":
            return "Something went wrong. Please try again."
          case "network_error":
            return "Unable to connect to internet. Please reconnect to try again."
        }
      },
    },
    (_) => undefined,
  )

  const buttonText: string = match(
    state,
    {
      Idle: (): string => "Start Verification",
      VeriffSdkError: ({ error }): string => {
        switch (error) {
          case "user_cancelled":
            return "Resume Verification"
          // TODO refresh is required now
          case "sdk_needs_reload":
            return "Reload"
          case "cannot_open":
            return "Start Verification"
          case "network_error":
            return "Start Verification"
        }
      },
    },
    (_) => "Continue",
  )

  const buttonState: ButtonState = match(
    state,
    {
      Idle: (): ButtonState => "active",
      SettingUpVeriffSession: (): ButtonState => ({
        loadingText: "Starting Identity Verification...",
      }),
      WaitingForVeriffSdk: (): ButtonState => ({
        loadingText: "Starting Identity Verification...",
      }),
      FetchingVeriffDecision: (): ButtonState => ({
        loadingText: "Processing Documents...",
      }),
      FetchingEffectivDecision: (): ButtonState => ({
        loadingText: "Processing Documents...",
      }),
      VeriffDecisionPending: (): ButtonState => "disabled",
    },
    (_) => "active" as const,
  )

  return (
    <ScreenFrame>
      <VStack height="full" justifyContent="space-between">
        <VStack height="full" gap="xl2" padding={{ bottom: "lg" }}>
          <VStack padding={{ bottom: "lg" }}>
            <Txt variant="headline2">More info needed to verify your identity</Txt>
            <Txt variant="body1">
              To ensure the safety of you and your family, we need more info to verify your
              identity. Please be prepared to do the following:
            </Txt>
          </VStack>

          <VStack gap="xl">
            <HStack alignItems="center" gap="md">
              <Avatar size="lg" svg={<IdCardIcon width={34} height={34} fill="icon-brand" />} />
              <Txt variant="subtitle2">Prepare your valid government issued ID</Txt>
            </HStack>

            <HStack alignItems="center" gap="md">
              <Avatar
                size="lg"
                svg={<DeviceMobileCameraIcon width={34} height={34} fill="icon-brand" />}
              />
              <Txt variant="subtitle2">Check if your device’s camera is uncovered and working</Txt>
            </HStack>

            <HStack alignItems="center" gap="md">
              <Avatar size="lg" svg={<CameraIcon width={34} height={34} fill="icon-brand" />} />
              <Txt variant="subtitle2">Be prepared to take photos of your ID and a selfie.</Txt>
            </HStack>
          </VStack>
        </VStack>

        <StickyBottom>
          <VStack gap="md">
            {sdkError && (
              <VStack gap="md">
                <Callout variant="error">
                  <HStack alignItems="center" gap="sm">
                    <AlertIcon fill="icon-error" />
                    <Txt variant="body2" color="text-error">
                      {sdkError}
                    </Txt>
                  </HStack>
                </Callout>
              </VStack>
            )}

            <VStack gap="sm" alignItems="center">
              <Button state={buttonState} onClick={handleContinue}>
                {buttonText}
              </Button>
            </VStack>
          </VStack>
        </StickyBottom>
      </VStack>

      <SubmittingDialog isOpen={state.tag === "FetchingVeriffDecision"} />
      {state.tag === "VeriffResubmissionRequired" && (
        <ResubmissionDialog
          isOpen={state.tag === "VeriffResubmissionRequired"}
          reason={state.data.reason}
          onContinue={() => {
            handleContinue()
          }}
        />
      )}
    </ScreenFrame>
  )
}
