/* tslint:disable */
/* eslint-disable */
/**
 * Pomelo Backend Server
 * This is the backend API
 *
 * The version of the OpenAPI document: 1.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type {
  EmailConfirmationRequest,
  EmailConfirmationResponse,
  EmailDto,
  EmailValidationResponse,
} from "../models"

export const attemptValidation = {
  url: "/v1/email/confirmation",
  method: "PUT",
  contentType: "application/json",
  meta: {} as {
    req: EmailConfirmationRequest
    res: EmailConfirmationResponse
  },
} as const

export const getEmail = {
  url: "/v1/email/{userIdent}",
  method: "GET",
  meta: {} as {
    pathParams: {
      userIdent: /* IS NOT ENUM */ string
    }
    res: EmailDto
  },
} as const

export const initiateValidation = {
  url: "/v1/email/validation",
  method: "POST",
  meta: {} as {
    res: EmailValidationResponse
  },
} as const
