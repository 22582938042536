import { AlertIcon } from "@pomebile/design-system"
import { Dialog } from "../../components/Dialog"
import { Avatar, VStack, Button, Txt } from "@pomebile/primitives"

export interface ResubmissionDialogProps {
  isOpen: boolean
  reason?: string
  onContinue: () => void
}

export const ResubmissionDialog = ({ reason, isOpen, onContinue }: ResubmissionDialogProps) => {
  return (
    <Dialog open={isOpen} onClose={onContinue}>
      <VStack padding="xl" gap="xl" justifyContent="center" alignItems="center">
        <Avatar
          size="lg"
          variant="error"
          svg={<AlertIcon width={34} height={34} fill="icon-error" />}
        />
        <VStack gap="xs">
          <Txt variant="headline2" as="h1" textAlign="center">
            Additional Information Required
          </Txt>
          <Txt variant="body1" textAlign="center">
            We were unable to succesfully verify your identity with the information you provided and
            additional documentation is required.
          </Txt>
          {reason ? (
            <Txt variant="body1" color="text-error" textAlign="center">
              {reason}
            </Txt>
          ) : (
            <Txt variant="body1" textAlign="center">
              You will need to provide additional information to complete identity verification.
            </Txt>
          )}
        </VStack>
        <Button web-type="button" onClick={onContinue}>
          Continue
        </Button>
      </VStack>
    </Dialog>
  )
}
