export const MSG_REQUIRED = "Required"
export const MSG_MAX = (max: number) => `max ${max}`
export const MSG_MIN = (min: number) => `min ${min}`
export const MSG_MIN_CHARACTERS = (min: number) => `Min ${min} characters`
export const MSG_MAX_CHARACTERS = (max: number) => `Max ${max} characters`
export const MSG_EMAIL = "Invalid email"
export const MSG_DATE = "Invalid date (format as MM/DD/YYYY)"
export const MSG_EXPIRATION_DATE = "Invalid expiration date"
export const MSG_LAST_4_SSN = "Last 4 digits of your SSN required"
export const MSG_SSN = "Invalid SSN"
export const MSG_ITIN = "ITIN not accepted, please enter your full SSN"
export const MSG_CARD_NUMBER = "Invalid card number"
export const MSG_CVC = "Invalid CVC"
export const MSG_INVALID_RECIPIENT = "GCash number does not exist"
export const MSG_INVALID_MOBILE_NUMBER = "Invalid mobile number"
export const MSG_INVALID_STATE = "Invalid State"
export const MSG_INVALID_ZIP = "Invalid Zip Code"
export const MSG_INVALID_POSTAL_CODE = "Invalid Postal Code"
export const MSG_PHONE_NUMBER = "Invalid Phone Number"
export const MSG_MIN_AGE = (minAge: number) => {
  return `Must be at least ${minAge} years or older`
}
export const MSG_INVALID_INCOME = "This amount is too high"
export const MSG_INVALID_OTP = (length: number) =>
  `Please input your ${length} digit one-time passcode`
export const MSG_NO_PO_BOX = "P.O. Boxes not accepted"
export const MSG_INVALID_CHARACTERS = "Invalid characters"
