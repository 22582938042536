/* tslint:disable */
/* eslint-disable */
/**
 * Pomelo Backend Server
 * This is the backend API
 *
 * The version of the OpenAPI document: 1.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type {
  VeriffActiveSessionResponse,
  VeriffDecisionResponse,
  VeriffFailureReasonResponse,
  VeriffSessionMobileRequest,
  VeriffSessionMobileResponse,
} from "../models"

export const generateVeriffSession = {
  url: "/v1/generateVeriffSession",
  method: "POST",
  contentType: "application/json",
  meta: {} as {
    req: VeriffSessionMobileRequest
    res: VeriffSessionMobileResponse
  },
} as const

export const getActiveVeriffSession = {
  url: "/v1/getActiveVeriffSession",
  method: "POST",
  contentType: "application/json",
  meta: {} as {
    req: VeriffSessionMobileRequest
    res: VeriffActiveSessionResponse
  },
} as const

export const getVeriffFailureReason = {
  url: "/v1/getVeriffFailureReason",
  method: "POST",
  contentType: "application/json",
  meta: {} as {
    req: VeriffSessionMobileRequest
    res: VeriffFailureReasonResponse
  },
} as const

export const queryVeriffDecision = {
  url: "/v1/queryVeriffDecision",
  method: "POST",
  contentType: "application/json",
  meta: {} as {
    req: VeriffSessionMobileRequest
    res: VeriffDecisionResponse
  },
} as const
