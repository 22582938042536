import { Txt, VStack } from "@pomebile/primitives"
import { SubmitButton } from "../components/Form/FormSubmitButton"
import { ScreenFrame } from "../components/ScreenFrame"
import { StickyBottom } from "../components/StickyBottom"
import { Link } from "@pomebile/primitives-web"
import { VSpace } from "@pomebile/design-system"
import { CreditApplicationOutcome, SubmitApplicationResponse } from "../api/webRoutes"
import { useLogging } from "../utils/logging"
import { SubmitStatus } from "../hooks/useSubmit"
import {
  Variants,
  match,
  matchDeferredTuple,
  taggedUnion,
  UpdateResult,
  commands,
  useMachine,
} from "@pomebile/shared/tagged-union"

export const MAX_TIME_LIMIT = 30000 // 30 seconds, max time limit we will continue to re-poll
const RETRY_INTERVAL = 5000 // 5 seconds, how often to re-poll

interface States {
  Idle: void
  SubmittingApplication: void
  CheckingApplicationStatus: {
    retryCount: number
  }
  Failed: void
  Done: void
  ReachedTimeLimit: void
}
type State = Variants<States>
export const State = taggedUnion<States>()

interface Events {
  ResubmittedApplication: void
  ApplicationSubmitted: void
  FetchedApplicationStatus: ReturnType<Commands["checkStatus"]>
  PollingDelayed: void
  Ready: void
}
type Event = Variants<Events>
export const Ev = taggedUnion<Events>()

interface Commands {
  resubmitApplication: () => SubmitApplicationResponse
  checkStatus: () => CreditApplicationOutcome | undefined
  onDone: (outcome: CreditApplicationOutcome | { tag: "reachedTimeLimit" }) => void
  pollingDelay: (time: number) => void
}
export const Cmd = commands<Commands>()

type UpdateFunc = (prev: State, ev: Event) => UpdateResult<State, Event, Commands>

export const updateState: UpdateFunc = matchDeferredTuple(State, Ev, {
  Idle: {
    ResubmittedApplication: () =>
      [
        State.SubmittingApplication(),
        Cmd.resubmitApplication({ onComplete: Ev.ApplicationSubmitted }),
      ] as const,
  },

  SubmittingApplication: {
    ApplicationSubmitted: () =>
      [
        State.CheckingApplicationStatus({ retryCount: 0 }),
        Cmd.checkStatus({ onComplete: Ev.FetchedApplicationStatus }),
      ] as const,
  },

  CheckingApplicationStatus: {
    FetchedApplicationStatus: ({ retryCount }, outcome) => {
      if (outcome !== undefined) {
        return [State.Done(), Cmd.onDone({ arg: outcome })] as const
      }

      if (MAX_TIME_LIMIT > retryCount * RETRY_INTERVAL) {
        return [
          State.CheckingApplicationStatus({ retryCount: retryCount + 1 }),
          Cmd.pollingDelay({
            arg: RETRY_INTERVAL,
            onComplete: Ev.PollingDelayed,
          }),
        ] as const
      }

      return [
        State.ReachedTimeLimit(),
        Cmd.onDone({ arg: { tag: "reachedTimeLimit" }, onComplete: Ev.Ready }),
      ] as const
    },
    PollingDelayed: (state) =>
      [
        State.CheckingApplicationStatus(state),
        Cmd.checkStatus({ onComplete: Ev.FetchedApplicationStatus }),
      ] as const,
  },

  Failed: {
    ResubmittedApplication: () =>
      [
        State.SubmittingApplication(),
        Cmd.resubmitApplication({ onComplete: Ev.ApplicationSubmitted }),
      ] as const,
  },

  Done: {},

  ReachedTimeLimit: {
    Ready: () => [State.Idle()] as const,
  },

  default: (prev) => [prev] as const,
})

export interface CreditFrozenProps {
  api: {
    resubmitApplication: () => Promise<SubmitApplicationResponse>
    checkStatus: () => Promise<CreditApplicationOutcome | undefined>
  }

  onDone: (outcome: CreditApplicationOutcome | { tag: "reachedTimeLimit" }) => void
}

export function CreditFrozen({
  api: { resubmitApplication, checkStatus },
  onDone,
}: CreditFrozenProps) {
  const logging = useLogging()
  const [state, send] = useMachine(
    updateState,
    () => [State.Idle()],
    {
      checkStatus,
      resubmitApplication,
      onDone: async (outcome) => onDone(outcome),
      pollingDelay: (time) => {
        return new Promise((resolve) => {
          setTimeout(() => {
            resolve()
          }, time)
        })
      },
    },
    (err: unknown) => {
      logging.logError(err)
      // display err UI but allow user to try again
      return [State.Failed()] as const
    },
  )

  return (
    <ScreenFrame>
      <VStack gap="xl2" height="full" justifyContent="space-between">
        <VStack gap="xl2">
          <VStack alignItems="center" gap="xs">
            <Txt variant="headline2" as="h1" textAlign="center" color="text-default">
              Frozen Credit
            </Txt>
            <Txt textAlign="center">
              It looks like your credit is frozen. To submit your application for Pomelo, you'll
              need to contact TransUnion and lift the freeze.
            </Txt>
          </VStack>
          <VStack gap="md">
            <Txt variant="button2" textAlign="center">
              Here's how to temporarily lift your credit freeze
            </Txt>
            <VStack gap="md">
              <Txt variant="body2">
                1. Go to{" "}
                <Link url="https://www.transunion.com/credit-freeze">
                  https://www.transunion.com/credit-freeze
                </Link>
              </Txt>
              <Txt variant="body2">
                2. To unfreeze for a specific timeframe, sign in to your TransUnion account online
                and select “Temporarily Lift Freeze”
              </Txt>
              <Txt variant="body2">
                3. Enter the dates you want your credit report to be unfrozen.
              </Txt>
              <Txt variant="body2">
                4. TransUnion will automatically add a freeze back on your credit report at midnight
                on the end date.
              </Txt>
            </VStack>
          </VStack>
        </VStack>
        <StickyBottom>
          <VStack justifyContent="center">
            <Txt textAlign="center" variant="caption">
              When you've unfrozen your credit:
            </Txt>
          </VStack>
          <VSpace height="md" />
          <SubmitButton
            status={match(
              state,
              {
                Done: (): SubmitStatus => "done",
                Failed: (): SubmitStatus => "submitErr",
                Idle: (): SubmitStatus => "idle",
                CheckingApplicationStatus: (): SubmitStatus => "submitting",
                SubmittingApplication: (): SubmitStatus => "submitting",
                ReachedTimeLimit: (): SubmitStatus => "idle",
              },
              (): SubmitStatus => "idle",
            )}
            disabled={match(
              state,
              {
                Done: () => true,
              },
              () => false,
            )}
            submit={() => send(Ev.ResubmittedApplication())}
          >
            Continue to apply
          </SubmitButton>
        </StickyBottom>
      </VStack>
    </ScreenFrame>
  )
}
