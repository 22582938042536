import { DeviceValidationResponseStatusEnum } from "@pomebile/pomelo-service-api"
import { ScreenFrame } from "../components/ScreenFrame"
import { Avatar, AvatarColorVariants, Txt, VStack, Button } from "@pomebile/primitives"
import { StickyBottom } from "../components/StickyBottom"
import { CrossIcon, CheckIcon, AlertIcon } from "@pomebile/design-system"
import { useEffect, useState } from "react"
import { deeplink } from "../utils/deeplink"
import { screenShellStyle } from "./EmailValidation.css"
import { screenContentStyle } from "./ScreenTransitionShell.css"
import { onlyShowOnMobileStyles } from "../sharedStyles.css"
import { createLoggingContext } from "../sharedShellLogic"
import { ScreenHeader } from "../components/ScreenHeader/ScreenHeader"
import { IntercomState } from "../utils/intercom"
import { emptyHeaderStyle } from "../AppShell.css"
import { queryDeviceVerification } from "../api/webRoutes.tsx"

type DeviceValidationStateType = "loading" | "verified" | "expired" | "supportRequired"
type DeviceValidationResultType = Exclude<DeviceValidationStateType, "loading">

export type DeviceValidationProps = {
  jwt: string
  baseUrl: string
}

export function DeviceVerification({ jwt, baseUrl }: DeviceValidationProps) {
  const { logEvent, logError } = createLoggingContext()
  const [deviceValidationState, setDeviceValidationState] =
    useState<DeviceValidationStateType>("loading")

  useEffect(() => {
    const changeEmailState = async (jwt: string) => {
      logEvent("Entered Email Validation Flow")
      try {
        const res = await queryDeviceVerification(jwt, baseUrl)
        switch (res.status) {
          case DeviceValidationResponseStatusEnum.Success:
            setDeviceValidationState("verified")
            logEvent("Email Validation Result: Verified")
            break
          case DeviceValidationResponseStatusEnum.AlreadyConfirmed:
          case DeviceValidationResponseStatusEnum.Expired:
            setDeviceValidationState("expired")
            logEvent("Email Validation Result: Expired")
            break
        }
      } catch (error) {
        setDeviceValidationState("supportRequired")
        logError("Email Validation Flow Error")
      }
    }

    changeEmailState(jwt)
    // eslint-disable-next-line
  }, [])

  const icon: {
    [key in DeviceValidationResultType]: { variant: AvatarColorVariants; svg: JSX.Element }
  } = {
    verified: {
      variant: "success",
      svg: <CheckIcon fill="icon-success" />,
    },
    expired: {
      variant: "error",
      svg: <AlertIcon fill="icon-error" />,
    },
    supportRequired: {
      variant: "error",
      svg: <CrossIcon fill="icon-error" />,
    },
  }

  const header: {
    [key in DeviceValidationResultType]: string
  } = {
    verified: "Device verified",
    expired: "Link no longer valid",
    supportRequired: "We couldn't verify your email",
  }

  const subTitle: {
    [key in DeviceValidationResultType]: string
  } = {
    verified: "Head back to the Pomelo app to finish logging in",
    expired:
      "It looks like the link has expired. Head back to the Pomelo app to request a new link to verify your device.",
    supportRequired: "",
  }

  const handleAppPress = () => {
    window.open(deeplink.DeviceValidationLink.link, "_blank")
  }

  return (
    <>
      <div className={emptyHeaderStyle} />
      <div className={screenShellStyle}>
        <div className={screenContentStyle}>
          <ScreenHeader activeSection="none" intercomState={IntercomState.Idle()} />
          <ScreenFrame>
            <VStack height="full" gap="xl2" justifyContent="center">
              {deviceValidationState !== "loading" && (
                <VStack gap="xs" alignItems="center">
                  <Avatar
                    size="md"
                    variant={icon[deviceValidationState].variant}
                    svg={icon[deviceValidationState].svg}
                  />
                  <VStack gap="xs">
                    <Txt variant="headline2" as="h1" textAlign="center">
                      {header[deviceValidationState]}
                    </Txt>
                    {deviceValidationState !== "supportRequired" && (
                      <Txt variant="body1" textAlign="center">
                        {subTitle[deviceValidationState]}
                      </Txt>
                    )}
                  </VStack>
                  {deviceValidationState === "supportRequired" && (
                    <Button web-type="button" variant="text" onClick={handleAppPress}>
                      Contact support
                    </Button>
                  )}
                </VStack>
              )}
              {["verified", "expired"].includes(deviceValidationState) && (
                <div className={onlyShowOnMobileStyles}>
                  <StickyBottom>
                    <Button onClick={handleAppPress}>Open Pomelo app</Button>
                  </StickyBottom>
                </div>
              )}
            </VStack>
          </ScreenFrame>
        </div>
      </div>
    </>
  )
}
