import { FC } from "react"
import { ChevronIcon, PomeloIcon, SupportIcon } from "@pomebile/design-system"
import { HStack, Link, Txt } from "@pomebile/primitives"
import { Progression } from "../../RPCOnboarding"
import { headerWrapper } from "./ScreenHeader.css"
import { IntercomState, useIntercomSevice } from "../../utils/intercom"

/* TODO: use this when we make header changeable */
type ScreenHeaderProps = {
  activeSection: Progression
  intercomState: IntercomState
  // progress: number // this will determine the progress bar percentage.
}

export const ScreenHeader: FC<ScreenHeaderProps> = ({ activeSection, intercomState }) => {
  const showProgression = activeSection !== "none"

  const intercom = useIntercomSevice()

  const useSupport = intercomState.tag === "Ready" && intercom

  return (
    <div className={headerWrapper}>
      <HStack
        padding={{ top: "md", bottom: "md", left: "xl", right: "xl" }}
        justifyContent={useSupport ? "space-between" : "center"}
        alignItems="center"
      >
        <PomeloIcon />
        {showProgression && (
          <HStack padding="xs" alignItems="center" justifyContent="center">
            <Txt
              variant="caption"
              color={activeSection === "apply" ? "text-default" : "text-disabled"}
            >
              Apply
            </Txt>
            <ChevronIcon fill="icon-default" direction="right" />
            <Txt
              variant="caption"
              color={activeSection === "accept-plan" ? "text-default" : "text-disabled"}
            >
              Accept Plan
            </Txt>
            <ChevronIcon fill="icon-secondary" direction="right" />
            <Txt variant="caption" color="text-disabled">
              Send Money
            </Txt>
          </HStack>
        )}
        {/* Wrapping support component with a div here to limit width */}
        {useSupport && (
          <div>
            <Link
              as="div"
              onClick={() => {
                intercom.showSpace("home")
              }}
            >
              <SupportIcon width={24} height={24} fill="icon-brand" />
            </Link>
          </div>
        )}
      </HStack>
    </div>
  )
}
