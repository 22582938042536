import { CrossIcon } from "@pomebile/design-system"
import { HStack, Txt, VStack } from "@pomebile/primitives"
import { Link } from "@pomebile/primitives-web"
import { DialogHeader } from "./Dialog/DialogHeader"
import { Dialog } from "./Dialog"

type TermsAndConditionsDialogProps = {
  open: boolean
  showGCashPromo: boolean
  onClose: () => void
}

// This should probably be promoted to a shared space at some point
export const TermsAndConditionsDialog = ({
  open,
  showGCashPromo,
  onClose,
}: TermsAndConditionsDialogProps) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogHeader>
        <HStack gap="xs" alignItems="center">
          <Txt variant="subtitle2">Terms and Conditions</Txt>
        </HStack>
        <HStack width="auto" justifyContent="flex-end" alignItems="center">
          <Link onClick={onClose}>
            {/* Link adds unecessary height so we start at flex end */}
            <VStack justifyContent="flex-end">
              <CrossIcon width={24} height={24} />
            </VStack>
          </Link>
        </HStack>
      </DialogHeader>
      <VStack padding="xl" gap="md">
        <VStack
          padding={{ bottom: "xl" }}
          border={{ widths: { bottom: "thin" }, color: "border-main" }}
          gap="sm"
        >
          <Link color="secondary" url="#new-accounts">
            Important Information for New Accounts
          </Link>
          <Link
            color="secondary"
            url="https://www.pomelo.com/legal/CHAgreement_Pomelo_v12.17.21_Clean-CCB-Approved-12.21.21.pdf"
            target="_blank"
          >
            Cardholder Agreement
          </Link>
          <Link color="secondary" url="#e-comms">
            Electronic Communications Agreement
          </Link>
          <Link color="secondary" url="https://www.pomelo.com/terms-of-service" target="_blank">
            Terms of Service
          </Link>
          <Link color="secondary" url="https://www.pomelo.com/privacy" target="_blank">
            Pomelo Privacy Policy
          </Link>
          <Link color="secondary" url="https://www.coastalbank.com/privacy-center/" target="_blank">
            Coastal Community Bank Privacy Policy
          </Link>
          {showGCashPromo && (
            <Link
              color="secondary"
              url="https://www.pomelo.com/legal/gcash-promo-terms"
              target="_blank"
            >
              Pomelo Promotional GCash Conversion Rate Terms and Conditions
            </Link>
          )}
          <Link
            color="secondary"
            url="https://www.pomelo.com/referral-program-rules"
            target="_blank"
          >
            Referral Promotion Official Rules
          </Link>
        </VStack>

        <Txt variant="headline3">
          <Link id="new-accounts">
            Important Information About Procedures for Opening a New Account
          </Link>
        </Txt>
        <Txt>
          To help the government fight the funding of terrorism and money laundering activities,
          Federal law requires all financial institutions to obtain, verify, and record information
          that identifies each person who opens an account.
        </Txt>
        <Txt>
          What this means for you: When you open an account, we will ask for your name, address,
          date of birth, and other information that will allow us to identify you. We may also ask
          to see your driver's license or other identifying documents.
        </Txt>
        <Txt variant="headline3">
          <Link id="e-comms">Pomelo E-Sign Disclosure and Consent Notice</Link>
        </Txt>
        <Txt weight="bold">
          This E-Sign Disclosure and Consent Notice ("Notice") applies to all communications for
          services provided by Pomelo International, Inc. and our affiliates ("Pomelo" or "We").
          Communications you receive in electronic form from Pomelo will be considered "in writing."
          By using Pomelo electronic and online services ("Electronic Services") you hereby consent
          to this Notice and affirm that you have access to the hardware and software requirements
          identified below. In addition, you must review and accept the terms of these services on
          the Pomelo website. If you do not consent to this Notice or you withdraw your consent, you
          will be restricted from using Electronic Services and will be charged fees for paper
          notices, as described in the Pomelo Terms and Conditions on the Pomelo Mobile App.
        </Txt>

        <Txt weight="bold">Electronic Signatures</Txt>
        <Txt color="text-disabled">
          In our sole discretion, the Communications we provide to you, or that you sign or agree to
          at our request, may be in electronic form ("Electronic Records"). We may also use
          electronic signatures and obtain them from you as part of our transactions with you.
          Sometimes the law, or our agreement with you, requires you to give us a written notice.
          You must still provide these notices to us on paper, unless we specifically tell you in
          another Communication how you may deliver that notice to us electronically.
        </Txt>

        <Txt weight="bold">Covered Communications</Txt>
        <Txt color="text-disabled">
          May include but are not limited to: Disclosures and communications we provide to you
          regarding our services such as: (i) terms and conditions, privacy statements, notices, and
          any changes thereto; (ii) deposit and transaction information; and (iii) customer service
          communications ("Communications"). There are certain Communications that by law we are not
          permitted to deliver to you electronically, even with your consent. So long as required by
          law, we will continue to deliver those Communications to you in writing. However, if the
          law changes in the future and permits any of those Communications to be delivered as
          Electronic Records, this Notice Consent will automatically cover those Communications as
          well.
        </Txt>

        <Txt weight="bold">Methods of Providing Communications</Txt>
        <Txt color="text-disabled">
          We may provide Communications to you by email, text message, or on the Pomelo websites,
          mobile applications, or mobile websites (including via "hyperlinks" provided on the mobile
          app, online, in e-mails, or in text messages).
        </Txt>

        <Txt weight="bold">Hardware and Software Requirements</Txt>
        <Txt color="text-disabled">
          To access and retain electronic Communications, you must have: {"\n"}
          {"\u2022"} A valid email address or mobile phone number; {"\n"}
          {"\u2022"} A computer, mobile phone, tablet or similar device with internet access and
          current software or applications that are capable of receiving, accessing, displaying, and
          either printing or storing Communications received in electronic form; {"\n"}
          {"\u2022"} Sufficient storage space to save Communications (whether presented on the
          mobile app, online, in e-mails, in text messages or PDF) or the ability to print
          Communications.
          {"\n"}
          {"\n"}
          We may request that you respond to an email or text message to demonstrate you are able to
          receive these Communications.
        </Txt>

        <Txt weight="bold">How to Withdraw Your Consent</Txt>
        <Txt color="text-disabled">
          You may withdraw your consent to receive electronic Communications by writing to us at
          Pomelo, P.O. Box 193425, San Francisco, CA 94119 or support@pomelo.com. Your withdrawal of
          consent will cancel your agreement to receive electronic Communications, and therefore,
          your ability to use our Electronic Services.
        </Txt>

        <Txt weight="bold">Requesting Paper Copies of Electronic Communications</Txt>
        <Txt color="text-disabled">
          If you request a paper copy of any Communications, we will mail you a copy via U.S. Mail.
          To request a paper copy of any Communications or to withdraw your consent to receive
          electronic Communications, write to us at Pomelo, P.O. Box 193425, San Francisco, CA 94119
          or support@pomelo.com.
        </Txt>
        <Txt color="text-disabled">
          If you request a paper copy of any Communications, we will ask you to provide your current
          mailing address so we can process this request.
        </Txt>

        <Txt weight="bold">Updating Your Contact Information</Txt>
        <Txt color="text-disabled">
          It is your responsibility to keep your primary email address and/or mobile phone number
          current so that Pomelo can communicate with you electronically. You understand and agree
          that if Pomelo sends you a Communication, but you do not receive it because your primary
          email address and/or mobile phone number on file is incorrect, out of date, blocked by
          your service provider, or you are otherwise unable to receive electronic Communications,
          Pomelo will be deemed to have provided the Communication to you.
        </Txt>
        <Txt color="text-disabled">
          You can update your primary email address or other information by logging on the Pomelo
          website or by writing to us at Pomelo, P.O. Box 193425, San Francisco, CA 94119 or
          support@pomelo.com.
        </Txt>

        <Txt weight="bold">Federal Law</Txt>
        <Txt color="text-disabled">
          You acknowledge and agree that your consent to electronic Communications is being provided
          in connection with a transaction affecting interstate commerce that is subject to the
          federal Electronic Signatures in Global and National Commerce Act (E-Sign Act), and that
          you and we both intend that the Act apply to the fullest extent possible to validate our
          ability to conduct business with you by electronic means.
        </Txt>

        <Txt weight="bold">Termination and Changes</Txt>
        <Txt color="text-disabled">
          We reserve the right, in our sole discretion, to discontinue the provision of your
          Communications, or to terminate or change the terms and conditions on which we provide
          Communications. We will provide you with notice of any such termination or change as
          required by law.
        </Txt>
      </VStack>
    </Dialog>
  )
}
