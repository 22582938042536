import { Path, Svg } from "@pomebile/primitives"
import { IconProps } from "./icon-contract"

export const SupportIcon = ({ width = 24, height = 24, fill = "icon-default" }: IconProps) => {
  return (
    <Svg width={width} height={height} customFill="none">
      <Path
        d="M13.125 16.875C13.125 17.0975 13.059 17.315 12.9354 17.5C12.8118 17.685 12.6361 17.8292 12.4305 17.9144C12.225 17.9995 11.9988 18.0218 11.7805 17.9784C11.5623 17.935 11.3618 17.8278 11.2045 17.6705C11.0472 17.5132 10.94 17.3127 10.8966 17.0945C10.8532 16.8762 10.8755 16.65 10.9606 16.4445C11.0458 16.2389 11.19 16.0632 11.375 15.9396C11.56 15.816 11.7775 15.75 12 15.75C12.2984 15.75 12.5845 15.8685 12.7955 16.0795C13.0065 16.2905 13.125 16.5766 13.125 16.875ZM12 6.75C9.93188 6.75 8.25 8.26406 8.25 10.125V10.5C8.25 10.6989 8.32902 10.8897 8.46967 11.0303C8.61032 11.171 8.80109 11.25 9 11.25C9.19891 11.25 9.38968 11.171 9.53033 11.0303C9.67098 10.8897 9.75 10.6989 9.75 10.5V10.125C9.75 9.09375 10.7597 8.25 12 8.25C13.2403 8.25 14.25 9.09375 14.25 10.125C14.25 11.1562 13.2403 12 12 12C11.8011 12 11.6103 12.079 11.4697 12.2197C11.329 12.3603 11.25 12.5511 11.25 12.75V13.5C11.25 13.6989 11.329 13.8897 11.4697 14.0303C11.6103 14.171 11.8011 14.25 12 14.25C12.1989 14.25 12.3897 14.171 12.5303 14.0303C12.671 13.8897 12.75 13.6989 12.75 13.5V13.4325C14.46 13.1184 15.75 11.7544 15.75 10.125C15.75 8.26406 14.0681 6.75 12 6.75Z"
        fill={fill}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.66094 19.6576C10.6731 20.2537 11.4782 20.5 11.9998 20.5C12.5215 20.5 13.3267 20.2537 14.3389 19.6575C15.3187 19.0805 16.3562 18.2561 17.3061 17.3061C18.2561 16.3562 19.0805 15.3188 19.6575 14.339C20.2537 13.3267 20.5 12.5216 20.5 12C20.5 11.4784 20.2537 10.6733 19.6575 9.66104C19.0805 8.68123 18.2561 7.64381 17.3061 6.69385C16.3562 5.7439 15.3187 4.9195 14.3389 4.34246C13.3267 3.74633 12.5215 3.5 11.9998 3.5C11.4782 3.5 10.6731 3.74631 9.66094 4.34244C8.68116 4.91948 7.64376 5.74388 6.69382 6.69384C5.74387 7.64379 4.91949 8.68122 4.34245 9.66103C3.74632 10.6732 3.5 11.4784 3.5 12C3.5 12.5216 3.74632 13.3268 4.34245 14.339C4.91949 15.3188 5.74387 16.3562 6.69382 17.3062C7.64376 18.2561 8.68116 19.0805 9.66094 19.6576ZM2 12C2 15.6448 8.35514 22 11.9998 22C15.6449 22 22 15.6448 22 12C22 8.35516 15.6449 2 11.9998 2C8.35514 2 2 8.35516 2 12Z"
        fill={fill}
      />
    </Svg>
  )
}
