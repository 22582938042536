import type { TxtComponent } from "@pomebile/primitives"
import { typographyAlignVariants, typographyColorVariants, typographyVariants } from "./Txt.css"
import { sprinkles } from "../../styles/sprinkles.css"
import { useSecureContext } from "../Secure/Secure"

export const Txt: TxtComponent = ({
  children,
  variant = "body1",
  as = "span",
  color = "text-default",
  textAlign = "left",
  weight = "regular",
  decoration,
}) => {
  const secureContext = useSecureContext()
  const logRocketProps = secureContext?.secure ? { "data-private": true } : {}

  const AsTag = as
  return (
    <AsTag
      {...logRocketProps}
      className={[
        typographyVariants[variant],
        typographyColorVariants[color],
        sprinkles({
          fontWeight: weight,
          textDecoration: decoration,
        }),
        typographyAlignVariants[textAlign],
      ].join(" ")}
    >
      {children}
    </AsTag>
  )
}
