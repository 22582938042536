import type {
  SvgComponent,
  PathComponent,
  GComponent,
  RectComponent,
  ClipPathComponent,
  DefsComponent,
  MaskComponent,
  ImageSvgComponent,
} from "@pomebile/primitives"
import { iconColors } from "@pomebile/primitives/tokens"
// ----- Svg -----
export const Svg: SvgComponent = ({
  children,
  fill = "icon-default",
  height,
  viewBox,
  width,
  customFill,
}) => (
  <svg
    height={height}
    width={width}
    viewBox={viewBox}
    fill={customFill || iconColors[fill]}
    xmlns="http://www.w3.org/2000/svg"
  >
    {children}
  </svg>
)

// ----- Path -----
export const Path: PathComponent = ({
  fill = "icon-default",
  fillRule,
  clipRule,
  d,
  stroke,
  strokeWidth,
  customFill,
  opacity,
}) => (
  <path
    fill={customFill || iconColors[fill]}
    fillRule={fillRule}
    clipRule={clipRule}
    d={d}
    stroke={stroke}
    strokeWidth={strokeWidth}
    opacity={opacity}
  />
)

export const G: GComponent = ({ children, clipPath, transform }) => (
  <g clipPath={clipPath} transform={transform}>
    {children}
  </g>
)

export const Rect: RectComponent = ({
  width,
  height,
  customFill,
  fill = "icon-default",
  clipPath,
  transform,
  x,
  y,
  rx,
  ry,
}) => (
  <rect
    width={width}
    height={height}
    x={x}
    y={y}
    rx={rx}
    ry={ry}
    fill={customFill || iconColors[fill]}
    transform={transform}
    clipPath={clipPath}
  />
)

export const ClipPath: ClipPathComponent = ({ id, children, clipPathUnits }) => (
  <clipPath id={id} clipPathUnits={clipPathUnits}>
    {children}
  </clipPath>
)

export const Defs: DefsComponent = ({ children }) => <defs>{children}</defs>

export const Mask: MaskComponent = ({ children, id, style, x, y, width, height }) => (
  <mask id={id} style={style} x={x} y={y} width={width} height={height}>
    {children}
  </mask>
)

export const ImageSvg: ImageSvgComponent = ({
  x,
  y,
  width,
  height,
  preserveAspectRatio,
  src,
  clipPath,
}) => (
  <image
    x={x}
    y={y}
    width={width}
    height={height}
    preserveAspectRatio={preserveAspectRatio}
    href={src}
    clipPath={clipPath}
  />
)
