import {
  AuthExistingUser,
  AuthTempToken,
  PHAddress,
  NrauPersonalInfo,
  Country,
  AnyAddress,
  USAddress,
} from "./api/webRoutes"
import { CardColors } from "@pomebile/primitives/tokens"
import {
  taggedUnion,
  matchDeferred,
  Variants,
  matchDeferredTuple,
} from "@pomebile/shared/tagged-union"
import { AuthData } from "./api/authContext"
import { PHAddressSchema } from "./screens/NrauHomeAddress"
import { USAddressSchema } from "./screens/HomeAddress"
import { NrauSignUpResponse, VeriffDetailsDtoVeriffStateEnum } from "@pomebile/pomelo-service-api"

export type NrauScreenKind = keyof Screens

interface Screens {
  NrauInvitationIntro: {
    inviteCode: string
  }
  Phone: { inviteCode: string; country: Country }
  NrauUserInfo: {
    smsTempToken: string
    inviteCode: string
    country: Country
    phoneNumber: string
  }
  OTP: {
    country: Country
    phoneNumber: string
  }
  NrauVerifyIdentity: {
    personalInfo: NrauPersonalInfo
    auth: AuthData
  }
  NrauHomeAddress: {
    country: Country
    auth: AuthData
  }
  CardSelector: {
    personalInfo: NrauPersonalInfo
    auth: AuthData
    address: AnyAddress
  }
  NrauComplete: void
  InvalidInvite: void
  TimeLimitError: { email: string }
  GeneralError: void
}

export const NrauAppScreen = taggedUnion<Screens>()
export type NrauAppScreen = Variants<Screens>
const {
  NrauInvitationIntro,
  OTP,
  NrauUserInfo,
  NrauVerifyIdentity,
  CardSelector,
  NrauComplete,
  InvalidInvite,
  TimeLimitError,
} = NrauAppScreen

interface NrauAppStates {
  Intro: { phoneNumber?: string; country: Country; inviteCode: string }
  Initial: { phoneNumber?: string; country: Country; inviteCode: string }
  Authenticating: { phoneNumber: string; country: Country; inviteCode: string }
  Joining: { inviteCode: string; tempToken: string; phoneNumber: string; country: Country }
  Verify: {
    personalInfo: NrauPersonalInfo
    auth: AuthData
    address?: AnyAddress // maybe this needs to be
  }
  GetCard: {
    personalInfo: NrauPersonalInfo
    auth: AuthData
    address?: AnyAddress
    selectedCard?: boolean
  }
  TimeoutError: { email: string }
  InviteError: void
  Complete: void
  GeneralError: void
}
export const NrauAppState = taggedUnion<NrauAppStates>()
export type NrauAppState = Variants<NrauAppStates>

const {
  Authenticating,
  Joining,
  Verify,
  GetCard,
  TimeoutError,
  Complete,
  InviteError,
  GeneralError,
} = NrauAppState

interface AppEvents {
  InvitationAccepted: void
  EnteredPhoneNumber: { phoneNumber: string }
  AuthCompleted: {
    authResult: AuthTempToken | AuthExistingUser | { tag: "disabledUser" }
    phoneNumber: string
  }

  SubmittedPersonalInfo: {
    personalInfo: NrauPersonalInfo
  }

  SignedUp: {
    personalInfo: NrauPersonalInfo
    signUpResult: NrauSignUpResponse
  }

  EncounteredInvalidInvite: void

  VerifiedIdentity: void

  AddedAddress: AnyAddress

  SelectedCard: CardColors

  // All errors should have an errorType
  // errorType will be exposed in Sentry breadcrumbs (don't include PII)

  EncounteredGeneralError: { errorType: string }

  TimeLimitExceeded: { errorType: string; email: string }
}

export const NrauAppEvent = taggedUnion<AppEvents>()
export type NrauAppEvent = Variants<AppEvents>

export const calculateNrauScreen: (state: NrauAppState) => NrauAppScreen = matchDeferred(
  NrauAppState,
  {
    Intro: ({ inviteCode }) => {
      return NrauAppScreen.NrauInvitationIntro({ inviteCode })
    },
    Initial: ({ inviteCode, phoneNumber, country }) => {
      if (!inviteCode) {
        return NrauAppScreen.InvalidInvite()
      }

      if (!phoneNumber) {
        return NrauAppScreen.Phone({ inviteCode, country })
      }

      return NrauAppScreen.NrauInvitationIntro({ inviteCode })
    },
    Authenticating: ({ country, phoneNumber }) => NrauAppScreen.OTP({ country, phoneNumber }),
    Joining: ({ inviteCode, tempToken, phoneNumber, country }) =>
      NrauAppScreen.NrauUserInfo({ inviteCode, smsTempToken: tempToken, phoneNumber, country }),
    Verify: ({ personalInfo, auth }) => {
      return NrauAppScreen.NrauVerifyIdentity({ auth, personalInfo })
    },
    GetCard: ({ personalInfo, auth, address }) => {
      // TODO: double chec, usage here.
      if (!address) {
        return NrauAppScreen.NrauHomeAddress({ auth, country: personalInfo.country })
      }

      return NrauAppScreen.CardSelector({ personalInfo, auth, address })
    },
    TimeoutError: ({ email }) => {
      return NrauAppScreen.TimeLimitError({ email })
    },
    InviteError: () => NrauAppScreen.InvalidInvite(),
    Complete: () => NrauAppScreen.NrauComplete(),
    GeneralError: () => NrauAppScreen.GeneralError(),
  },
)

export const updateNrauAppState = matchDeferredTuple(NrauAppState, NrauAppEvent, {
  // TODO: do we need an intro state here?
  Intro: {
    InvitationAccepted: ({ inviteCode, country, phoneNumber }) => {
      if (phoneNumber) {
        return NrauAppState.Authenticating({ phoneNumber, country, inviteCode })
      } else {
        return NrauAppState.Initial({ inviteCode, country })
      }
    },
  },
  Initial: {
    EnteredPhoneNumber: ({ inviteCode, country }, { phoneNumber }) => {
      return NrauAppState.Authenticating({ phoneNumber, country, inviteCode })
    },
  },
  Authenticating: {
    AuthCompleted: ({ inviteCode, country }, { authResult, phoneNumber }) => {
      if (authResult.tag === "disabledUser") {
        return GeneralError()
      } else if (authResult.tag === "newUser") {
        return Joining({ inviteCode, tempToken: authResult.smsTempToken, phoneNumber, country })
      } else {
        const { user, token, refreshToken } = authResult
        const { signUp, personalInfo } = user
        const { signUpContextDto } = signUp
        const auth: AuthData = {
          tokens: { token, refreshToken },
          email: personalInfo.email,
          userIdent: user.ident,
        }

        const nrauPersonalInfo: NrauPersonalInfo = {
          firstName: personalInfo.firstName,
          lastName: personalInfo.lastName,
          email: personalInfo.email,
          phoneNumber,
          country,
          dateOfBirth: personalInfo.dateOfBirth?.join("/") || "",
        }

        const userAddress = personalInfo.address

        let parsedAddress: AnyAddress | undefined

        if (userAddress && userAddress.country === "PH") {
          const {
            lineOne = "",
            lineTwo,
            city = "",
            zip,
            locality = "",
            region: state = "",
            country,
          } = userAddress
          const a: PHAddress = {
            addressLineOne: lineOne,
            addressLineTwo: lineTwo,
            barangay: locality,
            postalCode: zip,
            province: state,
            cityOrMunicipality: city,
            country,
          }

          if (PHAddressSchema.isValidSync(a)) {
            parsedAddress = a
          }
        }

        if (userAddress && userAddress.country === "US") {
          const { lineOne = "", lineTwo, city = "", zip, region: state = "", country } = userAddress

          const a: USAddress = {
            addressLineOne: lineOne,
            addressLineTwo: lineTwo,
            zip,
            state,
            city,
            country,
          }

          if (USAddressSchema.isValidSync(a)) {
            parsedAddress = a
          }
        }

        const hasSelectedCard =
          signUpContextDto?.cardColorSelectionRequirement?.signUpRequirementStatus === "COMPLETE"

        if (!parsedAddress || !hasSelectedCard) {
          return GetCard({
            personalInfo: nrauPersonalInfo,
            auth,
            address: parsedAddress,
          })
        }

        const submittedVeriffStates: VeriffDetailsDtoVeriffStateEnum[] = [
          "review",
          "declined",
          "approved",
          "submitted",
        ]
        const veriffState = signUpContextDto?.idVerificationRequirement?.veriffDetails?.veriffState
        const isVeriffSubmitted = veriffState ? submittedVeriffStates.includes(veriffState) : false

        // TODO Eventually we might want to handle declined/review state differently, but for now just send the user to the app.
        if (!isVeriffSubmitted) {
          return Verify({ personalInfo: nrauPersonalInfo, auth, address: parsedAddress })
        }

        return Complete()
      }
    },

    EncounteredInvalidInvite: () => InviteError(),
  },

  Joining: {
    SignedUp: (
      _,
      {
        personalInfo,
        signUpResult: {
          user: { ident: userIdent },
          authToken: token,
          refreshToken,
        },
      },
    ) => {
      const auth: AuthData = {
        tokens: { token, refreshToken },
        userIdent,
        email: personalInfo.email,
      }
      return GetCard({ personalInfo, auth })
    },

    EncounteredInvalidInvite: () => InviteError(),
  },

  Verify: {
    VerifiedIdentity: () => Complete(),

    TimeLimitExceeded: (_, { email }) => {
      return TimeoutError({ email })
    },
  },

  GetCard: {
    AddedAddress: (prev, address) => {
      return GetCard(/* tag: "GetCard", data: { */ { ...prev, address })
    },

    SelectedCard: (prev) => {
      return Verify({ ...prev })
    },

    TimeLimitExceeded: (_, { email }) => {
      return TimeoutError({ email })
    },
  },

  default: (prev) => prev,
})
