import { PomeloCardIcon, ActivityIcon } from "@pomebile/design-system"
import { Txt, VStack, Button } from "@pomebile/primitives"
import { StickyBottom } from "../components/StickyBottom"
import { ScreenFrame } from "../components/ScreenFrame"
import { AppDownloadQRForDesktop } from "../components/AppDownloadQRForDesktop"
import { onlyShowOnMobileStyles } from "../sharedStyles.css"
import { deeplink } from "../utils/deeplink"
import { Promo } from "../api/webRoutes"
import { FxPromoBanner } from "../components/FxPromoBanner"
import { logoStyles } from "./Complete.css"

interface IconItemProps {
  icon: JSX.Element
  text?: undefined
  title: string
  subtitle: string
}
interface TextItemProps {
  text: JSX.Element
  icon?: undefined
  title: string
  subtitle: string
}

type ItemProps = IconItemProps | TextItemProps

export type ProductTypes = "unsecured" | "secured"

interface CompleteProps {
  promos: Promo[]
  productType: ProductTypes
}

export function Complete({ promos, productType }: CompleteProps) {
  const handleDownloadPress = () => {
    window.open(deeplink.OnboardingV2DownloadLink.link, "_blank")
  }

  const rowData: ItemProps[] = []

  const gcashPromo = promos.find((promo) => promo.type === "MTP_RATE" && promo.active)
  if (gcashPromo) {
    rowData.push({
      text: (
        <Txt variant="headline3" as="span">
          ₱{gcashPromo.rate}
        </Txt>
      ),
      subtitle: "Promotional exchange rate lasts for a limited time only.",
      title: `Claim your $1 = ₱${gcashPromo.rate} Promo`,
    })
  }

  rowData.push(
    {
      icon: <PomeloCardIcon />,
      subtitle: "Once activated, use it to spend anywhere Mastercard is accepted.",
      title: "Activate your Pomelo Mastercard®",
    },
    {
      icon: <ActivityIcon />,
      subtitle: "Stay on top of all your transactions and payments.",
      title: "Track transactions and payments",
    },
  )

  return (
    <ScreenFrame>
      <VStack gap="xl3" justifyContent="space-between" height="full">
        <VStack gap="xl2" justifyContent="center" alignItems="center">
          <VStack alignItems="center">
            <Txt variant="headline2" as="h1" textAlign="center">
              Now, let's download Pomelo to send money
            </Txt>
            <VStack padding={{ top: "xs" }}>
              <Txt as="p" variant="body1" textAlign="center">
                Use your {productType === "unsecured" ? "new Pomelo Mastercard®" : "debit card"} to
                send money to
                <img src="images/gcash_logo.png" height={19.8} className={logoStyles} />
                now {productType === "unsecured" && "and pay later "}
                in the Pomelo app.
              </Txt>
            </VStack>
          </VStack>
          {gcashPromo && (
            <VStack height="full">
              <FxPromoBanner
                caption="Promotional rate applies to the first GCash transaction up to $500 sent by new customers."
                promoRate={gcashPromo.rate}
              />
            </VStack>
          )}
          <AppDownloadQRForDesktop type="OnboardingV2DownloadLink" />
        </VStack>
        <div className={onlyShowOnMobileStyles}>
          <StickyBottom>
            <Button onClick={handleDownloadPress}>Download Pomelo App</Button>
          </StickyBottom>
        </div>
      </VStack>
    </ScreenFrame>
  )
}
