import { Secure, Txt } from "@pomebile/primitives"
import { ScreenFrame } from "../components/ScreenFrame"
import { sprinkles } from "@pomebile/primitives-web"

export type TimeLimitErrorScreenProps = {
  email?: string
}

export function TimeLimitErrorScreen({ email }: TimeLimitErrorScreenProps) {
  return (
    <ScreenFrame>
      <Txt variant="headline2" as="h1">
        Sorry, this is taking longer than expected.
      </Txt>
      {/* Todo: for when we have no email, we need a more generic error */}
      {email && (
        <Secure>
          <Txt as="p" variant="body1">
            We will send an email to{" "}
            <span className={sprinkles({ wordBreak: "break-all" })}>{email}</span> when we've
            successfully processed your info.
          </Txt>
        </Secure>
      )}
    </ScreenFrame>
  )
}
