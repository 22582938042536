import { HStack } from "@pomebile/primitives"
import { stickyTop } from "./Dialog.css"

type DialogHeaderProps = {
  children?: JSX.Element[]
}

export const DialogHeader = ({ children }: DialogHeaderProps) => {
  return (
    <div className={stickyTop}>
      <HStack
        justifyContent="space-between"
        border={{ widths: { bottom: "thin" }, color: "border-main" }}
        padding={{ x: "lg", y: "md" }}
      >
        {children}
      </HStack>
    </div>
  )
}
